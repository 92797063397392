@import url('https://fonts.googleapis.com/css2?family=Trispace:wght@200;400;600;700&display=swap');

@font-face {
    font-family: "Troubled PB";
    src: url('./fonts/Troubled-PB.ttf');
    font-weight: 400;
    font-style: normal;
}

@tailwind base;

::-webkit-scrollbar {
    width: 20px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
}

@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
    /*scroll-snap-type: y mandatory;*/
}

body {
    overflow-y: overlay;
    overflow-x: hidden;
}

.animate-heartBeat {
    animation: heartBeat 3s linear infinite;
}


.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

@keyframes heartBeat {
    0% {
        transform: scale(1);
    }

    40% {
        transform: scale(1.01);
    }

    50% {
        transform: scale(1.01) rotate(3deg);
    }

    55% {
        transform: scale(1.01) rotate(-3deg);
    }

    60% {
        transform: scale(1.01) rotate(3deg);
    }

    65% {
        transform: scale(1.01) rotate(-3deg);
    }

    70% {
        transform: scale(1.01) rotate(3deg);
    }

    75% {
        transform: scale(1.01) rotate(-3deg);
    }

    100% {
        transform: scale(1);
    }
}


.animate-slowBounce {
    animation: slowBounce 10s linear infinite;
}

.animate-slowBounce-2 {
    animation: slowBounce 7s linear infinite;
}

.animate-slowBounce-3 {
    animation: slowBounce 8s linear infinite;
}

.animate-slowBounce-4 {
    animation: slowBounce 16s linear infinite;
}

@keyframes slowBounce {
    0% {
        transform: translateY(0px);
    }

    25% {
        transform: translateY(-40px) scale(1.01);
    }

    50% {
        transform: translateY(0px);
    }

    75% {
        transform: translateY(40px) scale(1.01);
    }

    100% {
        transform: translateY(0px);
    }
}

.animate-walk {
    animation: walk 5s linear infinite;
}

@keyframes walk {
    0% {
        transform: translate(0, 0);
    }

    33.33% {
        transform: translate(10px, 0);
    }

    66.66% {
        transform: translate(0, 10px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.animate-spinSlow {
    animation: spinSlow 15s linear infinite;
}

@keyframes spinSlow {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.animate-sideWalk {
    animation: sideWalk 15s linear infinite;
}

@keyframes sideWalk {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(-100px);
    }

    100% {
        transform: translateX(0px);
    }
}

.ainmate-loopThrough {
    animation: loopThrough 8s linear infinite;
}

@media screen and (max-width: 1024px) {
    .ainmate-loopThrough {
        animation: loopThroughLeft 8s linear infinite;
    }
}

.ainmate-loopThrough:nth-child(2) {
    animation-delay: -8s;
}

.ainmate-loopThrough-wrapper:hover .ainmate-loopThrough {
    animation-play-state: paused;
}

@keyframes loopThrough {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-100%)
    }
}

@keyframes loopThroughLeft {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%)
    }
}

.animate-translateYFull .animate-content {
    animation: translateYFull 6s linear;
    animation-fill-mode: forwards;
}

.animate-translateYFull .animate-content.play-pause {
    animation-play-state: paused;
}

.animate-translateYFull .animate-content.reverse {
    animation-direction: reverse;
}

@keyframes translateYFull {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(calc(-100% + 700px));
    }
}

@media screen and (max-width:1024px) {
    html {
        scroll-snap-type: none;
    }
    @keyframes translateYFull {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(calc(-100% + 560px));
        }
    }
}

@media screen and (max-width: 640px) {
    @keyframes translateYFull {
        0% {
            transform: translateY(0);
        }

        100% {
            transform: translateY(calc(-100% + 420px));
        }
    }
}

.animate-singleBounce img {
    animation: singleBounce .5s linear forwards;
}

@keyframes singleBounce {
    30% {
        transform: scale(1.02);
    }

    40%,
    60% {
        transform: rotate(-2deg) scale(1.02);
    }

    50% {
        transform: rotate(2deg) scale(1.02);
    }

    70% {
        transform: rotate(0deg) scale(1.02);
    }

    100% {
        transform: scale(1);
    }
}

.animate-fun-wrap.style-init div:nth-child(1) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[30deg] origin-bottom-right;
}

.animate-fun-wrap.style-init div:nth-child(2) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-[30deg] origin-bottom-left;
}

.animate-fun-wrap.style-init div:nth-child(3) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-1/2 scale-75 -translate-y-1/4 -rotate-[20deg];
}

.animate-fun-wrap.style-1 div:nth-child(1) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-[60%] -translate-y-3/4 -rotate-[25deg] origin-bottom-left scale-[.35];
}

.animate-fun-wrap.style-1 div:nth-child(2) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-[45deg] origin-bottom-left scale-[.35];
}

.animate-fun-wrap.style-1 div:nth-child(3) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rotate-[12deg] origin-bottom-right;
}

.animate-fun-wrap.style-2 div:nth-child(1) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-[35%] -translate-y-[60%] -rotate-[10deg] origin-bottom-left;
}

.animate-fun-wrap.style-2 div:nth-child(2) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -rotate-[45deg] origin-bottom-left scale-[.35];
}

.animate-fun-wrap.style-2 div:nth-child(3) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-1/3 -translate-y-1/2 -rotate-[12deg] origin-bottom-left scale-[.35] bottom-5 right-10;
}

.animate-fun-wrap.style-3 div:nth-child(1) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-y-[90%] -translate-x-[35%] rotate-[25deg] origin-bottom-right scale-[.35];
}

.animate-fun-wrap.style-3 div:nth-child(2) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-[65%] -translate-y-1/2 rotate-[10deg] origin-bottom-right;
}

.animate-fun-wrap.style-3 div:nth-child(3) {
    @apply duration-500 absolute top-1/2 left-1/2 -translate-x-[60%] -translate-y-1/2 -rotate-[35deg] origin-bottom-left scale-[.35] right-20;
}

.active-scroll-spy {
    color: #EFD95B;
}

.active-white a.active {
    @apply text-[#E64083]
}

.active-red a.active {
    @apply text-[#EFD95B]
}

.chapter-slider .slick-prev {
    display: none !important;
}

.chapter-slider .slick-next,
.chapter-slider .slick-dots {
    top: -55px;
    left: 25%;
    width: auto;
    height: fit-content;
    display: flex;
}

.chapter-slider .slick-dots {
display: flex !important;
}

.slick-dots li {
    margin: 0;
}

.chapter-slider .slick-dots li button:before {
color: rgba(255, 255, 255, .6);
}

.chapter-slider .slick-dots li.slick-active button:before {
color: #F42272;
font-size: 9px;
}

.chapter-slider .slick-next::before {
display: none;
}

.chapter-arrow-next {
@apply absolute top-1/2 right-0 transform -translate-y-1/2 -rotate-90;
}

.chapter-arrow-prev {
@apply absolute top-1/2 left-0 transform -translate-y-1/2 rotate-90;
}

.team-container {
}

.team-element {
    flex: 1;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}